<template>
  <div>
    <el-row v-loading="evaluationLoadStatus == 1">
      <el-col :span="24">
        <form-evaluation ref="formEval"> </form-evaluation>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormEvaluation from "@/components/rh/ui/form/FormEvaluation";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-evaluation": FormEvaluation },

  data() {
    return {
      formEvalEdit: {},
    };
  },
  props: {
    routes: Object,
  },
  created() {
    this.$store.dispatch("eva/loadEvaluation", this.idEval);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idEval() {
      // récupère le id du tutorat dans l'url
      return String(this.$route.params.ideval);
    },
    evaluationLoadStatus() {
      return this.$store.state.eva.evaluationLoadStatus;
    },
    evaluationUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.eva.evaluationUpdateStatus;
    },
  },
  destroyed() {
    // supprime le nom nom du fichier pour créer l'url -> évite que le fichier s'affiche si prochain form est create
    this.$store.commit("eva/setEvalFileNom", "");
  },
  methods: {
    afficheData() {
      if (this.evaluationLoadStatus == 2) {
        // charge les données de l'évaluation
        const eva = this.$store.state.eva.evaluation;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.evaLoaded = JSON.stringify([eva.eval_date, eva.remarques]);
      } else if (this.evaluationLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formEval.$refs.formEvaluation.validate((valid) => {
        if (valid) {
          let evadata = this.$store.state.eva.evaluation;

          // enlever les espaces avant et après dans les string
          trimStringInObj(evadata);

          const evasInBD = this.$store.getters["eva/EvasInBD"];
          const id = this.idEval;
          const doublon =
            evasInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == evadata.id_medtuteur &&
                m[2] == evadata.eval_date
            ).length > 0;
          // prépare les données pour les comparer avec les données avant modification et pour l'enregistrement
          const evadataStr = JSON.stringify([
            evadata.eval_date,
            evadata.remarques,
          ]);

          // vérifie si les données ont été modifiées
          if (localStorage.evaLoaded == evadataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si un tutorat entre les mêmes méd et avec la même date de debut n'est pas déjà enregistré
          } else if (doublon) {
            this.$alert(
              "Un tutorat entre ces 2 personnes avec la même date de début est déjà enregistré !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("eva/updateEvaluation", evadata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // prépare les données pour les comparer avec les données avant modification
      const evadata = this.$store.state.eva.evaluation;
      const evadataStr = JSON.stringify([evadata.eval_date, evadata.remarques]);
      if (localStorage.evaLoaded != evadataStr) {
        // si les données ont été modifiées
        let dataArr = JSON.parse(localStorage.evaLoaded);
        this.$store.commit("eva/canceleditEvaluation", dataArr);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modification
      const evadata = this.$store.state.eva.evaluation;
      const evadataStr = JSON.stringify([evadata.eval_date, evadata.remarques]);

      if (localStorage.evaLoaded == evadataStr) {
        // si pas de modifications
        // retourne à la liste des tutorats
        this.$router.push(this.routes.tolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des tutorats
          this.$router.push(this.routes.tolist);
        });
      }
    },
  },
  watch: {
    evaluationLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    evaluationUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.evaluationUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        const evadata = this.$store.state.eva.evaluation;
        localStorage.evaLoaded = JSON.stringify([
          evadata.eval_date,
          evadata.remarques,
        ]);
      } else if (this.evaluationUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
