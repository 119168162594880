<template>
  <el-main class="rh-form">
    <form-evaluationedit :routes="routes"></form-evaluationedit>
  </el-main>
</template>
<script>
import FormEvaluationedit from "@/components/rh/formulaires/FormEvaluationedit";

export default {
  components: { "form-evaluationedit": FormEvaluationedit },
  data() {
    return {
      routes: {
        tolist: {
          name: "tutoratedit",
          params: { idtutorat: this.$route.params.idtutorat },
        },
      },
    };
  },
};
</script>
